import {Button, Text, Title} from "../../components/Core";
import {Col, Row} from "react-bootstrap";
import React, {useContext} from "react";
import { Link } from "gatsby"
import styled from "styled-components";
import appointmentOK from "../../assets/image/svg/youfibre-appointment.svg";
import GlobalContext from "../../context/GlobalContext";

const BigTitle = styled(Title)` 
  font-size:2.8em;
`

const SuccessAppointment = () => {

    const gContext = useContext(GlobalContext)

    return (
        <Row className="justify-content-center">
            <Col sm={12} lg={11} className="text-center mb-5">
                <img src={appointmentOK} alt="Appointment OK" style={{maxWidth: '80px'}} />
            </Col>
            <Col sm={12} lg={11} className="mx-auto">
                <div className="mb-3 pb-2">
                    <BigTitle className="mt-3 mb-2 text-center">
                        Thank you for confirming your install date!
                    </BigTitle>
                </div>
            </Col>
            <Col sm={12} lg={4} className="mx-auto">
                <Link to={"/"}>
                    <Button width="100%" borderRadius={10} className="mx-auto" onClick={() => {gContext.goSetRegistrationData(null)}}>
                        Return to homepage
                    </Button>
                </Link>
            </Col>

        </Row>

    )
}

export default SuccessAppointment